import Cookies from "universal-cookie"
import { get } from 'lodash';
import { MYACCOUNT_LOGIN } from "../../constants/urls";
import { doRedirect } from "../../components/MyAccount/utils";
import { updateToken } from "./api"
const cookies = new Cookies()

const isBrowser = () => {
    return typeof window !== `undefined`
}

const apiUrl = () => {
    return process.env.REACT_APP_API_URL || process.env.GATSBY_API_URL || null
}

// https://developer.mozilla.org/en-US/docs/Web/API/btoa
const encodeData = (data) => {
    if (isBrowser() && data) {
        if (typeof btoa === 'function') {
            try {
                return btoa(JSON.stringify(data));
            } catch (e) {
                console.warn(e)
            }
        }
    }
    return data
}

const decodeData = (data) => {
    if (isBrowser() && data) {
        if (typeof atob === 'function') {
            try {
                return JSON.parse(atob(data));
            } catch (e) {
                console.warn(e)
                doLogout()
            }
        }
    }
    return data
}

const cookieDomain =
    process.env.REACT_APP_COOKIE_DOMAIN ||
    process.env.GATSBY_COOKIE_DOMAIN ||
    null

const storeData = (key, value, type = 'token', customExpires=false) => {
    if (isBrowser()) {
        // localStorage.setItem(key, JSON.stringify(value))
        let expires = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
        if (type === 'auth')
            expires = new Date(new Date().setMinutes(new Date().getMinutes() + 15));
        if (customExpires)
            expires = customExpires;
        const options = {
            expires: expires,
            sameSite: true,
            path: '/'
        }
        if (cookieDomain) options["domain"] = cookieDomain

        cookies.set(key, value, options)
    }
}

const getData = key => {
    return isBrowser() ? cookies.get(key) : null
    // return isBrowser() ? JSON.parse(localStorage.getItem(key)) : null
}

const removeData = key => {
    if (isBrowser()) {
        const options = {
            path: '/'
        }
        // Remove deafult
        cookies.remove(key, options)
        // Remove with domain deafult
        if (cookieDomain) options["domain"] = cookieDomain
        cookies.remove(key, options)
        // localStorage.removeItem(key)
    }
}

const storeUserData = user => {
    let userData = {
        name: (user.forename || user.username || user.email).trim(),
        surname: user.surname || null,
        email: user.email,
        mobile: user.mobile || user.telephone,
        // job_title: user?.job_title,
        uid: user.id,
        contact_id: get(user, 'contact_id[0]', false),
        picture: getUserPicture(user)
    }
    storeData("suser", encodeData(userData));
}

const getUser = () => {
    return isBrowser() ? decodeData(cookies.get("suser")) : {}
    // return (isBrowser() ? JSON.parse(localStorage.getItem("suser")) : {});
}

const getToken = () => {
    return isBrowser() ? cookies.get("sjwt") : null
}

const getUserPicture = (user) => {
    return user?.picture || user?.social_picture || null;
}

const doLogout = () => {
    removeData('sjwt');
    removeData('suser');
    doRedirect(MYACCOUNT_LOGIN)
}

const querystring = (params) => {
    return new URLSearchParams(params).toString()
}

const storeToken = (data) => {
    // Store token
    if (isBrowser()) {
      storeData('sjwt', data.jwt);
      storeUserData(data.user);
    }
    updateToken(data.jwt);
  };

export {
    isBrowser,
    apiUrl,
    storeData,
    getData,
    getUserPicture,
    removeData,
    storeUserData,
    getUser,
    getToken,
    doLogout,
    querystring,
    storeToken
}
